import React, { useEffect, useState } from "react";
import ProductsSlider from "../ProductsSlider";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  PneumaticsProductsList,
  PneumaticsSlides1,
  PneumaticsSlides2,
  SwitchGearsSlides3,
} from "../../Data/companiesData";
import { RxCross2 } from "react-icons/rx";

function Pneumatics() {
  const [selectedImage, setSelectedImage] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };
  return (
    <div className="mx-auto px-4 py-8 bg-slate-900 min-h-screen">
      <h2 className="text-3xl font-bold mb-3 text-white lg:mx-auto px-2 lg:border-b-2 lg:pb-1 lg:border-pink-500 text-center lg:rounded-md w-fit">
        PNEUMATICS
      </h2>
      <h2 className="text-3xl font-bold px-2 mb-3 text-white lg:mx-auto lg:border-b-2 lg:pb-1 lg:border-pink-500 text-center lg:rounded-md w-fit">
        Brands We Deal In
      </h2>
      <div className="mb-3">
        <Carousel
          swipeable={false}
          draggable={false}
          showDots={false}
          infinite={true}
          autoPlay={true}
          ssr={true}
          autoPlaySpeed={2000}
          keyBoardControl={true}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          responsive={responsive}
        >
          {PneumaticsSlides1.map((item) => (
            <ProductsSlider key={item.id} imageUrl={item.imageUrl} />
          ))}
        </Carousel>
      </div>
      <div className="mb-3">
        <Carousel
          swipeable={false}
          draggable={false}
          showDots={false}
          infinite={true}
          autoPlay={true}
          ssr={true}
          autoPlaySpeed={2000}
          keyBoardControl={true}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          responsive={responsive}
        >
          {PneumaticsSlides2.map((item) => (
            <ProductsSlider key={item.id} imageUrl={item.imageUrl} />
          ))}
        </Carousel>
      </div>
      <div className="mb-5">
        <Carousel
          swipeable={false}
          draggable={false}
          showDots={false}
          infinite={true}
          autoPlay={true}
          ssr={true}
          autoPlaySpeed={2000}
          keyBoardControl={true}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          responsive={responsive}
        >
          {SwitchGearsSlides3.map((item) => (
            <ProductsSlider key={item.id} imageUrl={item.imageUrl} />
          ))}
        </Carousel>
      </div>
      <div className="border-b-2 border-gray-500 w-full">
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-10">
        {PneumaticsProductsList.map((item) => (
          <div className="bg-white shadow-lg rounded-lg overflow-hidden cursor-pointer">
            <img
              src={item.imageUrl}
              alt={item.companyName}
              className="w-full h-52 object-cover object-center"
              onClick={() => openModal(item.imageUrl)}
            />
            <div className="p-4">
              {/* <h3 className="text-xl font-bold mb-2">{item.companyName}</h3> */}
              <ul className="list-disc pl-5">
                {item.productCategories.map((category) => (
                  <li key={category} className="text-md">
                    {category}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
      {selectedImage && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="max-w-xl w-full mx-4 bg-white rounded-lg overflow-hidden">
            <img src={selectedImage} alt="Products" className="w-full" />
            <button
              onClick={closeModal}
              className="absolute top-14 right-10 m-4 text-slate-300 hover:text-white"
            >
              <RxCross2 size={36} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Pneumatics;
