import React from "react";
import "../App.css";
import { Link } from "react-router-dom";
import ProductsSlideImg1 from "../assets/Products/MainSlider/festo.jpg";
import ProductsSlideImg2 from "../assets/Products/MainSlider/omron.jpg";
// import ProductsSlideImg3 from "../assets/Products/MainSlider/havells.jpg";
import ProductsSlideImg4 from "../assets/Products/MainSlider/Anchor.png";
import ProductsSlideImg5 from "../assets/Products/MainSlider/Autonics.png";
import ProductsSlideImg6 from "../assets/Products/MainSlider/Indfos.png";
import ProductsSlideImg7 from "../assets/Products/MainSlider/Invonance.png";
import ProductsSlider from "../Components/ProductsSlider";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Products = () => {
  const items = [
    { id: 1, imageUrl: ProductsSlideImg1 },
    { id: 2, imageUrl: ProductsSlideImg2 },
    // { id: 3, imageUrl: ProductsSlideImg3 },
    { id: 4, imageUrl: ProductsSlideImg4 },
    { id: 5, imageUrl: ProductsSlideImg5 },
    { id: 6, imageUrl: ProductsSlideImg6 },
    { id: 7, imageUrl: ProductsSlideImg7 },
    // Add more items as needed
  ];
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="mx-auto px-4 py-8 bg-slate-900 min-h-screen">
      <h2 className="text-3xl font-bold mb-3 text-white lg:mx-auto lg:border-b-2 lg:pb-1 lg:border-pink-500 text-center lg:rounded-md lg:w-[15%]">
        Products
      </h2>
      <div className="mb-5">
        <Carousel
          swipeable={false}
          draggable={false}
          showDots={true}
          infinite={true}
          autoPlay={true}
          ssr={true}
          autoPlaySpeed={2000}
          keyBoardControl={true}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          responsive={responsive}
        >
          {items.map((item) => (
            <ProductsSlider key={item.id} imageUrl={item.imageUrl} />
          ))}
        </Carousel>
      </div>

      {/* Create three buttons which have to render */}
      <div className="flex justify-center items-center mt-10  gap-4 flex-wrap">
        <Link to="/products/SwitchYear" className="w-full md:w-1/3 ">
          <button className="w-full button-border bg-slate-500 hover:bg-slate-600 text-white px-4 py-4 rounded-md text-xl md:text-2xl font-bold">
          INDUSTRIAL SWITCHGEARS
          </button>
        </Link>
        <Link to="/products/Hydraulics" className="w-full md:w-1/3">
          <button className="w-full button-border bg-slate-500 text-white hover:bg-slate-600 px-4 py-4 rounded-md text-xl md:text-2xl font-bold">
          HYDRAULICS
          </button>
        </Link>
        <Link to="/products/Pneumatics" className="w-full md:w-1/3">
          <button className="w-full button-border bg-slate-500 text-white px-4 hover:bg-slate-600 py-4 rounded-md text-xl md:text-2xl font-bold">
          PNEUMATICS
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Products;
