import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowCircleLeft } from "react-icons/fa";
import { FaArrowCircleRight } from "react-icons/fa";
import banner1 from "../assets/1.jpg";
import banner2 from "../assets/2.jpg";
import banner3 from "../assets/3.jpg";
import banner4 from "../assets/4.jpg";

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <button
      className="slick-arrow absolute top-1/2 transform -translate-y-1/2 left-0 bg-gray-800 text-white p-3 rounded-full z-10"
      onClick={onClick}
      aria-label="Previous"
    >
      {/* Use an icon or any text for the arrow */}
      <FaArrowCircleLeft size={33} />
      {/* &lt; */}
    </button>
  );
};

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <button
      className="slick-arrow absolute top-1/2 transform -translate-y-1/2 right-0 rounded-full bg-gray-800 text-white p-3 z-10"
      onClick={onClick}
      aria-label="Next"
    >
      {/* Use an icon or any text for the arrow */}
      <FaArrowCircleRight size={33} />
      {/* &gt; */}
    </button>
  );
};

const HomeSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <Slider {...settings} className="mt-10">
      <div>
        <img
          src={banner1}
          alt="Slide 1"
          className="h-auto mx-auto rounded-md w-[90%]"
        />
      </div>
      <div>
        <img
          src={banner2}
          alt="Slide 2"
          className="h-auto mx-auto rounded-md w-[90%]"
        />
      </div>
      <div>
        <img
          src={banner3}
          alt="Slide 3"
          className="h-auto mx-auto rounded-md w-[90%]"
        />
      </div>
      <div>
        <img
          src={banner4}
          alt="Slide 4"
          className="h-auto mx-auto rounded-md w-[90%]"
        />
      </div>
    </Slider>
  );
};

export default HomeSlider;
