import React, { useState } from "react";
import { Link } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { IoReorderThree } from "react-icons/io5";
import logo from "../assets/logo.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="flex items-center justify-between bg-gray-800 p-6">
      <div className="flex items-center flex-shrink-0 text-white mr-6">
        <img
          src={logo}
          alt="Logo"
          className="h-12 w-auto mr-2 p-1 rounded bg-white"
        />
      </div>
      {/* Mobile and Tablet Menu */}
      <div className="block lg:hidden">
        <button
          onClick={toggleMenu}
          className="flex items-center px-3 py-2 rounded text-gray-300 border-gray-300 hover:text-white hover:border-white"
        >
          <IoReorderThree size={33} />
        </button>
      </div>
      {/* Desktop Menu */}
      <div className="hidden lg:flex lg:items-center lg:w-auto">
        <ul className="flex space-x-4">
          <li>
            <Link
              to={"/"}
              className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
            >
              HOME
            </Link>
          </li>
          <li>
            <Link
              to={"/About"}
              className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
            >
              ABOUT US
            </Link>
          </li>
          <li>
            {" "}
            <Link
              to={"/Products"}
              className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
            >
              PRODUCTS
            </Link>
          </li>
          {/* <li>
            <Link
              to={"/Suppliers"}
              className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
            >
              SUPPLIERS
            </Link>
          </li> */}
          <li>
            <Link
              to={"/Enquiry"}
              className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
            >
              ENQUIRY
            </Link>
          </li>
          <li>
            <Link
              to={"/Contact"}
              className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
            >
              CONTACT
            </Link>
          </li>
        </ul>
      </div>
      {/* Mobile and Tablet Menu (shown only when isOpen is true) */}
      {isOpen && (
        <div className="lg:hidden absolute top-0 left-0 w-full bg-gray-800 z-20">
          {/* create cross button */}
          <div className="flex justify-end p-4 pb-0">
            <button
              onClick={toggleMenu}
              className="text-gray-300 hover:text-white focus:outline-none"
            >
              <RxCross2 size={30} />
            </button>
          </div>
          <div className="p-4">
            <Link
              to="/"
              onClick={toggleMenu}
              className="block text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-base font-medium"
            >
              HOME
            </Link>
            <Link
              to="/About"
              onClick={toggleMenu}
              className="block text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-base font-medium"
            >
              ABOUT US
            </Link>
            <Link
              to="/Products"
              onClick={toggleMenu}
              className="block text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-base font-medium"
            >
              PRODUCTS
            </Link>
            {/* <Link
              to="/Suppliers"
              onClick={toggleMenu}
              className="block text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-base font-medium"
            >
              SUPPLIERS
            </Link> */}
            <Link
              to="/Enquiry"
              onClick={toggleMenu}
              className="block text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-base font-medium"
            >
              ENQUIRY
            </Link>
            <Link
              to="/Contact"
              onClick={toggleMenu}
              className="block text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-base font-medium"
            >
              CONTACT
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
