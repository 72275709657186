import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Products from "./Pages/Products";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Contact from "./Pages/Contact";
import Enquiry from "./Pages/Enquiry";
import SwitchYear from "./Components/ProductsSection/SwitchGears";
import Hydraulics from "./Components/ProductsSection/Hydraulics";
import Pneumatics from "./Components/ProductsSection/Pneumatics";

function App() {
  return (
    <div className="bg-slate-900">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Products" element={<Products />} />
        <Route path="/Products/SwitchYear" element={<SwitchYear />} />
        <Route path="/Products/Hydraulics" element={<Hydraulics />} />
        <Route path="/Products/Pneumatics" element={<Pneumatics />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Enquiry" element={<Enquiry />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
