import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import AboutImg from "../assets/aboutM.jpg";

const About = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div
      className={`flex flex-col lg:flex-row items-center justify-center bg-slate-900 ${
        location.pathname === "/" ? "mt-20" : "p-3"
      }`}
    >
      {/* Left Side - Image */}
      <div
        className={`lg:w-1.4/4 ${
          location.pathname === "/" ? "hidden" : "block"
        }`}
      >
        <img src={AboutImg} alt="About" className="w-full h-auto lg:rounded" />
      </div>

      {/* Right Side - Text */}
      <div className="lg:w-2/3 p-6 text-white">
        <h2
          className={`text-3xl font-bold mb-4 text-white lg:border-b-2 lg:pb-1 lg:border-pink-500 flex justify-center lg:rounded-md lg:w-[25%] ${
            location.pathname === "/" ? "hidden" : "flex"
          }`}
        >
          About Us
        </h2>
        <p
          className={`text-lg mb-4 text-justify ${
            location.pathname === "/" ? "flex" : "hidden"
          }`}
        >
          Our firm ARORA ELECTRIC WORKS is based in one of the biggest
          industrial hubs of India i.e. near Delhi in between twin city
          Yamunanagar- Jagadhri. Since 1984, we are specialized in Supply of
          various Industrial electrical as well as pneumatic goods having a
          range of Over 2000 products. • L&T, Schneider, SIEMENS, Chint, OMRON,
          Hager Switchgear • Contactors, MPCB, MCCB, RCCB etc. • Smps, Proximity
          sensor, SSR, PID controller, Speed Controller. • kVar Capacitor. •
          Copper, Aluminum Lugs/Thimbles and Crimping Tools. 0.75mmsq-400mmsq
          Industrial Cables. • Any Type of Rotary Encoder and sensors. • All
          kind of Panel Accessories • Crane Remotes, wired/wireless •
          Temperature Controller and Timer. • Pneumatic Spares such as Pressure
          Switch, Air Cylinders, Solenoid Vavle, PU pipes, etc •
          Festo/SMC/Spac/Janatics etc. • Any type of panel meter as per your
          requirement. •Any requirement can be sent via email/WhatsApp at -
          sales.aroraelectric@gmail.com or +91-8295533245 / +91- 9468405656.
        </p>
        <Link
          to="/about"
          className={`text-white hover:text-slate-300 border-2 border-blue-700 p-2 m-1 rounded-md text-xl ${
            location.pathname === "/" ? "blocked" : "hidden"
          }`}
        >
          Read More....
        </Link>
        <p
          className={`text-lg mb-4 text-justify ${
            location.pathname === "/" ? "hidden" : "flex"
          }`}
        >
          Our firm Arora Electric Works is one of the best electric shop
          situated in the midst of the Industrial Hub of Ambala, Saha,
          Yamunanagar, Kala Amb and Poanta Sahib. Serving the Industries and
          Residential Hubs since 1985, we are famous amongst the esteemed
          Wholesaler, Stockist, Retailer and Trader of reputed Industrial as
          well as Residential Brands. In Industrial Sector our products range
          consists of Timers, Multifunctional Meters, Controllers, Rotary
          Encoders, Photoelectric Sensor, PLC, Drives, Axial Fans, SMPS, Relays,
          Limit Switch and Many more. Other Industrial Products offered are
          manufactured as per the application need under the supervision of
          quality controllers. These products are known for high performance,
          easy usability, damage resistance, and long service life. In
          Residential sector , our Products Range includes Electrical Fitting
          Pipes, Fancy Lights , MCBs, RCCBs, MCCBs, Switches of Reputed Brands
          Giving an Architectural Style to your Home and Many more
        </p>
        <p className="text-lg mb-4"></p>
      </div>
    </div>
  );
};

export default About;
