// Switch Gears Items slider
import SwitchGearsSlideImg1 from "../assets/Products/SwitchGears/Slider/Autonics.png";
import SwitchGearsSlideImg2 from "../assets/Products/SwitchGears/Slider/Connectwell.png";
import SwitchGearsSlideImg3 from "../assets/Products/SwitchGears/Slider/Crompton_Greaves.jpg";
import SwitchGearsSlideImg4 from "../assets/Products/SwitchGears/Slider/ABB.jpg";
import SwitchGearsSlideImg5 from "../assets/Products/SwitchGears/Slider/Eapl.jpg";
import SwitchGearsSlideImg6 from "../assets/Products/SwitchGears/Slider/Inovence.png";
import SwitchGearsSlideImg7 from "../assets/Products/SwitchGears/Slider/L&T.png";
import SwitchGearsSlideImg8 from "../assets/Products/SwitchGears/Slider/Rexnord.png";
import SwitchGearsSlideImg9 from "../assets/Products/SwitchGears/Slider/Schneider.jpg";
import SwitchGearsSlideImg10 from "../assets/Products/SwitchGears/Slider/Siemens.png";
import SwitchGearsSlideImg11 from "../assets/Products/SwitchGears/Slider/Swastik.png";
import SwitchGearsSlideImg12 from "../assets/Products/SwitchGears/Slider/chint.png";
import SwitchGearsSlideImg14 from "../assets/Products/SwitchGears/Slider/hager.jpg";
import SwitchGearsSlideImg15 from "../assets/Products/SwitchGears/Slider/hpl.png";
import SwitchGearsSlideImg16 from "../assets/Products/SwitchGears/Slider/Jaibalaji.png";
import SwitchGearsSlideImg17 from "../assets/Products/SwitchGears/Slider/keftronic.png";
import SwitchGearsSlideImg18 from "../assets/Products/SwitchGears/Slider/minilec.png";
import SwitchGearsSlideImg19 from "../assets/Products/SwitchGears/Slider/mutispan.png";
import SwitchGearsSlideImg20 from "../assets/Products/SwitchGears/Slider/pepperl-fuchs.png";
import SwitchGearsSlideImg21 from "../assets/Products/SwitchGears/Slider/omron.jpg";
import SwitchGearsSlideImg22 from "../assets/Products/SwitchGears/Slider/selec.png";
import SwitchGearsSlideImg23 from "../assets/Products/SwitchGears/Slider/yudan.png";

// Switch-gears Products List Image
import SG1 from "../assets/Products/SwitchGears/ProductList/PL1.png";
import SG2 from "../assets/Products/SwitchGears/ProductList/PL2.png";
import SG3 from "../assets/Products/SwitchGears/ProductList/PL3.png";
import SG4 from "../assets/Products/SwitchGears/ProductList/PL4.png";
import SG5 from "../assets/Products/SwitchGears/ProductList/PL5.png";
import SG6 from "../assets/Products/SwitchGears/ProductList/PL6.png";
import SG7 from "../assets/Products/SwitchGears/ProductList/PL7.png";
import SG8 from "../assets/Products/SwitchGears/ProductList/PL8.png";

// // Hydraulic Items Sliders
import HydraulicsSlideImg1 from "../assets/Products/Hydraulics/Slider/ARB.png";
import HydraulicsSlideImg2 from "../assets/Products/Hydraulics/Slider/Danfoss.png";
import HydraulicsSlideImg3 from "../assets/Products/Hydraulics/Slider/NBC.jpg";
import HydraulicsSlideImg4 from "../assets/Products/Hydraulics/Slider/Spica.jpg";
import HydraulicsSlideImg5 from "../assets/Products/Hydraulics/Slider/WIKA.jpg";
import HydraulicsSlideImg6 from "../assets/Products/Hydraulics/Slider/hiwin.jpg";
import HydraulicsSlideImg7 from "../assets/Products/Hydraulics/Slider/indfos.png";
import HydraulicsSlideImg8 from "../assets/Products/Hydraulics/Slider/polyhydron.png";
import HydraulicsSlideImg9 from "../assets/Products/Hydraulics/Slider/rexroth.jpg";
import HydraulicsSlideImg10 from "../assets/Products/Hydraulics/Slider/rotex.png";
import HydraulicsSlideImg11 from "../assets/Products/Hydraulics/Slider/yuken.png";

// // Hydraulics Products List Image
import HL1 from '../assets/Products/Hydraulics/ProductList/PL1.jpg';
import HL2 from '../assets/Products/Hydraulics/ProductList/PL2.jpg';
import HL3 from '../assets/Products/Hydraulics/ProductList/PL3.jpg';

// // Pneumatics Items slider
import PneumaticsSlideImg1 from "../assets/Products/Pneumatics/Slider/CAM-Metalic.png";
import PneumaticsSlideImg2 from "../assets/Products/Pneumatics/Slider/Mercury.png";
import PneumaticsSlideImg3 from "../assets/Products/Pneumatics/Slider/airtac.png";
import PneumaticsSlideImg4 from "../assets/Products/Pneumatics/Slider/festo.png";
import PneumaticsSlideImg5 from "../assets/Products/Pneumatics/Slider/janatics.png";
import PneumaticsSlideImg6 from "../assets/Products/Pneumatics/Slider/smc.png";
import PneumaticsSlideImg7 from "../assets/Products/Pneumatics/Slider/spac.png";
// import PneumaticsSlideImg8 from "../assets/Products/Pneumatics/Slider/Atos.png";
// import PneumaticsSlideImg9 from "../assets/Products/Pneumatics/Slider/Dowty.png";
// import PneumaticsSlideImg10 from "../assets/Products/Pneumatics/Slider/Eaton.png";

// // Pneumatics Products List Image
import PN1 from '../assets/Products/Pneumatics/ProductList/PL1.jpg';
import PN2 from '../assets/Products/Pneumatics/ProductList/PL2.jpg';
import PN3 from '../assets/Products/Pneumatics/ProductList/PL3.jpg';

/*****************************************    SwitchGears Products             *********************************/
export const SwitchGearsSlides1 = [
  { id: 1, imageUrl: SwitchGearsSlideImg1 },
  { id: 2, imageUrl: SwitchGearsSlideImg2 },
  { id: 3, imageUrl: SwitchGearsSlideImg3 },
  { id: 4, imageUrl: SwitchGearsSlideImg4 },
  { id: 5, imageUrl: SwitchGearsSlideImg5 },
  { id: 6, imageUrl: SwitchGearsSlideImg6 },
  { id: 7, imageUrl: SwitchGearsSlideImg7 },
  { id: 8, imageUrl: SwitchGearsSlideImg8 },
];
export const SwitchGearsSlides2 = [
  { id: 9, imageUrl: SwitchGearsSlideImg9 },
  { id: 10, imageUrl: SwitchGearsSlideImg10 },
  { id: 11, imageUrl: SwitchGearsSlideImg11 },
  { id: 12, imageUrl: SwitchGearsSlideImg12 },
  { id: 14, imageUrl: SwitchGearsSlideImg14 },
  { id: 15, imageUrl: SwitchGearsSlideImg15 },
  { id: 16, imageUrl: SwitchGearsSlideImg16 },
];

export const SwitchGearsSlides3 = [
  { id: 17, imageUrl: SwitchGearsSlideImg17 },
  { id: 18, imageUrl: SwitchGearsSlideImg18 },
  { id: 19, imageUrl: SwitchGearsSlideImg19 },
  { id: 20, imageUrl: SwitchGearsSlideImg20 },
  { id: 21, imageUrl: SwitchGearsSlideImg21 },
  { id: 22, imageUrl: SwitchGearsSlideImg22 },
  { id: 23, imageUrl: SwitchGearsSlideImg23 },
];
export const SwitchGearsProductsList = [
  {
    id: 1,
    companyName: "Schneider",
    imageUrl: SG8,
    productCategories: [
      "Moulded Case Circuit Breaker (MCCB)",
      "Air Circuit Breaker (ACB), ACB Spares",
      "Vacuum Circuit Breaker (VCB)",
      "MPCB(Motor Protection Circuit Breaker)",
      "Control Panel Accessories",
    ],
  },
  {
    id: 2,
    companyName: "Hager",
    imageUrl: SG1,
    productCategories: [
      "Axial Fans",
      "Contractors,OLR",
      "Starter(Mk1,Mk2,ML,Mu)",
      "HourMeters,Counters(Digital,Analog)",
      "Cylindrical Capacitors",
    ],
  },
  {
    id: 3,
    companyName: "Selec",
    imageUrl: SG2,
    productCategories: [
      "Lighting Management Control",
      "Digital Meters(Voltage,Amperes)",
      "Power Supplies(SMPS)",
      "Earth Switches",
      "Earth Leakage Relay",
    ],
  },
  {
    id: 4,
    companyName: "Omron",
    imageUrl: SG3,
    productCategories: [
      "ChangeOver Switches",
      "Industrial Plug & Sockets",
      "RCCB`s",
      'Tower Lights',
      "Voltage Monitoring Devices",
      "MFM`s(Multi Function Meters)",
    ],
  },
  {
    id: 5,
    companyName: "Festo",
    imageUrl: SG4,
    productCategories: [
      "APFC`s", 
      "Energy Meters", 
      "LimitSwitches",
      'Relay cards',
      'Pour light'
    ],
  },
  {
    id: 6,
    companyName: "yuken",
    imageUrl: SG5,
    productCategories: [
      'Safety Certain Sensors',
      'Distribution Boxes',
      'Terminal Connectors',
      'Temperature Controllers',
      'Inductive & Capacitive Sensors',
    ],
  },
  {
    id: 7,
    companyName: "Hiwin",
    imageUrl: SG6,
    productCategories: [
      'Pressure Sensors',
      'Rotary Unicoders',
      'Ultrasonic sensors',
      "Voltage Monitoring Devices",
      "MFM`s(Multi Function Meters)",
    ],
  },
  {
    id: 8,
    companyName: "Havells",
    imageUrl: SG7,
    productCategories: [
      "Lighting Management Control",
      "Digital Meters(Voltage,Amperes)",
      "Vacuum Circuit Breaker (VCB)",
      "MPCB(Motor Protection Circuit Breaker)",
      "Control Panel Accessories",
    ],
  },
];
/*****************************************    Hydraulics Products             *********************************/

export const HydraulicsSlides1 = [
  { id: 1, imageUrl: HydraulicsSlideImg1 },
  { id: 2, imageUrl: HydraulicsSlideImg2 },
  { id: 3, imageUrl: HydraulicsSlideImg3 },
  { id: 4, imageUrl: HydraulicsSlideImg4 },
];
export const HydraulicsSlides2 = [
  { id: 5, imageUrl: HydraulicsSlideImg5 },
  { id: 6, imageUrl: HydraulicsSlideImg6 },
  { id: 7, imageUrl: HydraulicsSlideImg7 },
  { id: 8, imageUrl: HydraulicsSlideImg8 },
];
export const HydraulicsSlides3 = [
  { id: 9, imageUrl: HydraulicsSlideImg9 },
  { id: 10, imageUrl: HydraulicsSlideImg10 },
  { id: 11, imageUrl: HydraulicsSlideImg11 },
];

export const HydraulicsProductsList = [
  {
    id: 1,
    companyName: 'Parker',
    imageUrl: HL1,
    productCategories: [
      'Directional Control Valves',
      'Hydraulics Pumps',
      'Pressure Switches',
    ],
  },
  {
    id: 2,
    companyName: 'Rexroth',
    imageUrl: HL2,
    productCategories: [
      'Pressures Relief Values',
      'Linear Guide Rails',
      'Hydraulic Filters',
      'Rod End Breakings',
      'Oil Seals',
    ]
  },
  {
    id: 3,
    companyName: 'Atos',
    imageUrl: HL3,
    productCategories: [
      'Hydraulic Cylinders',
      'Hydraulic Motors',
    ]
  },
];

/*****************************************    Pneumatics Products            *********************************/

export const PneumaticsSlides1 = [
  { id: 1, imageUrl: PneumaticsSlideImg1 },
  { id: 2, imageUrl: PneumaticsSlideImg2 },
  { id: 3, imageUrl: PneumaticsSlideImg3 },
  { id: 4, imageUrl: PneumaticsSlideImg4 },
];
export const PneumaticsSlides2 = [
  { id: 5, imageUrl: PneumaticsSlideImg5 },
  { id: 6, imageUrl: PneumaticsSlideImg6 },
  { id: 7, imageUrl: PneumaticsSlideImg7 },
];
// export const PneumaticsSlides3 = [
//   { id: 8, imageUrl: PneumaticsSlideImg8 },
//   { id: 9, imageUrl: PneumaticsSlideImg9 },
//   { id: 10, imageUrl: PneumaticsSlideImg10 },
// ];

export const PneumaticsProductsList = [
  {
    id: 1,
    companyName: 'Parker',
    imageUrl: PN1,
    productCategories: [
      'Standard Pneumatic Cylinders',
      'One Touch Fittings',
      'Jointers',
      'Connectors',
      'PU Pipes',
    ],
  },
  {
    id: 2,
    companyName: 'Rexroth',
    imageUrl: PN2,
    productCategories: [
      'Pneumatic Tubings(Pipes)',
      'Special Cylinders',
      'Filter,Regulators,& Lubricators(FRL`s)',
      'Cylinder Accessories',

    ]
  },
  {
    id: 3,
    companyName: 'Festo',
    imageUrl: PN3,
    productCategories: [
      'Solenoid Valves',
      'Pneumatic Tools(Staplers,Sanders etc.)',
      'Pneumatic Tubings',
      'Diaphragm, Battery Valves',
    ]
  },
];
